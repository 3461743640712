const permissionsInfo = {
  Categories: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Blogs: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  BlogTags: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Clinics: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  HospitalSections: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Diagnoses: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  TypeOfDiagnoses: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Staff: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Banners: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Contents: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  MediaObjects: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Faq: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  FaqType: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Files: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  FileTypes: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Gallery: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  News: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  PriceList: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Users: {
    Create: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Read: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Update: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
    Delete: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
  },
  Default: {
    default: [],
  },
};

export const PermissionsNames = {
  ROLE_SUPER_ADMIN: "Admin wszystkiego",
  ROLE_ADMIN: "Admin",
  ROLE_USER: "Użytkownik",
};

export const GetPermissionNamesFromArray = (names) => {
  return Array.isArray(names)
    ? names?.map((x) => PermissionsNames[x])?.join(", ")
    : names;
};

export const PermissionHandler = (
  permissions = null,
  moduleName = "Default",
  actionName = "default"
) => {
  let result = false;

  if (
    permissions &&
    moduleName &&
    actionName &&
    permissionsInfo.hasOwnProperty(moduleName)
  )
    permissions.split(",").map((x) => {
      if (permissionsInfo[moduleName][actionName])
        if (permissionsInfo[moduleName][actionName].includes(x)) result = true;
    });

  return result;
};
